import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import Image from '@components/Image';

import Section from '@components/Section';
import mediaqueries from '@styles/media';
import Icons from "@icons";

const authorQuery = graphql`
  {
    site: allSite {
      edges {
        node {
          siteMetadata {
            hero {
              writingHeading
              maxWidth
            }
          }
        }
      }
    }
    file(relativePath: {eq: "portfolio-hero.png"}) {
      id
      childImageSharp {
        fluid(maxWidth: 820, quality: 100, base64Width: 1) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const PortfoliosHero: React.FC<{}> = () => {

  const results = useStaticQuery(authorQuery);
  const heroImage = results.file.childImageSharp.fluid;

  return (
    <Section narrow>
      <VisuallyHidden>Trong Nguyen, Product Designer, Hobbyist Writer</VisuallyHidden>
      <Wrapper>
        <HeroImage>
          <Image src={heroImage} />
        </HeroImage>
        <HeroTextWrapper>
          <Icons.PortfolioHeroTagline />
        </HeroTextWrapper>
      </Wrapper>
    </Section>
  );
};

export default PortfoliosHero;

const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;

  ${mediaqueries.tablet`
    padding-top: 126px;
  `}
`;

const  HeroImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
  height: auto;
  border-radius: 400px 400px 0 0;
  overflow: hidden;

  ${mediaqueries.tablet`
    width: 80%;
    max-width: 100%;
  `}
`;

const HeroTextWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 180px;

  svg {
    width: 100%;
  }

`;

const VisuallyHidden = styled.h1`
  position: absolute;
  overflow: hidden;
  clip: rect(1px,1px,1px,1px);
`;