import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import mediaqueries from '@styles/media';
import { IArticle } from '@types';
import Icons from "@icons";

import Headings from '@components/Headings';
import Image, { ImagePlaceholder } from '@components/Image';

import { GridLayoutContext } from '../articles/Articles.List.Context';

interface ArticlesListProps {
  articles: IArticle[];
  alwaysShowAllDetails?: boolean;
}

interface ArticlesListItemProps {
  article: IArticle;
  narrow?: boolean;
  counter: number;
}

const PortfolioList: React.FC<ArticlesListProps> = ({ articles }) => {
  if (!articles) return null;

  const totalCount = articles.length;


  return (
    <Container>
      <SectionTitle> <em>Case studies</em></SectionTitle>
      <ArticlesListContainer>
        <List>
          {articles.map((ap, index) => {
            return (
              <ListItem key={index} article={ap} counter={totalCount - index}/>
            );
          })}
        </List>
      </ArticlesListContainer>
    </Container>
  );
};

export default PortfolioList;

const ListItem: React.FC<ArticlesListItemProps> = ({ article, narrow, counter }) => {
  if (!article) return null;

  const { gridLayout } = useContext(GridLayoutContext);
  const hasOverflow = narrow && article.title.length > 35;
  const imageSource = article.thumbnail.regular;
  const hasHeroImage =
    imageSource &&
    Object.keys(imageSource).length !== 0 &&
    imageSource.constructor === Object;

  return (
    <ArticleLink to={article.slug} data-a11y="false">
      <Item>
        <ImageContainer style={{backgroundColor: article.backgroundColor}}>
          {hasHeroImage ? <Image src={imageSource} alt={article.title} imgStyle={{ objectFit: 'cover', objectPosition: 'right bottom' }} /> : <ImagePlaceholder />}
        </ImageContainer>

        <TextContainer dark={article.dark} backgroundColor={article.backgroundColor}>
          { article.eyebrowHeadline && 
            <EyebrowHeading>
              <em>{counter}—{article.eyebrowHeadline}</em>
            </EyebrowHeading>
          }
          <Title>
            {article.title}
          </Title>
          <Excerpt
            narrow={narrow}
            hasOverflow={hasOverflow}
            gridLayout={gridLayout}
          >
            {article.excerpt}
          </Excerpt>
          <SeeMore>
            <IconWrap>
              <Icons.ArrowForward /> 
            </IconWrap>
            <span>View Case Study</span>
          </SeeMore>
        </TextContainer>

      </Item>
    </ArticleLink>
  );
};

const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;

  ${mediaqueries.phablet`
    -webkit-line-clamp: 3;
  `}
`;

const Container = styled.div`
  position: relative;
  margin: 160px 0 160px 0; 

  ${mediaqueries.tablet`
    margin-top: 96px;
  `}
`;

const SectionTitle = styled(Headings.h4)`
  position: relative;
  width: 100%;
  padding: 16px 0;
  margin-bottom: 96px;
  box-shadow: inset 0px -2px 0px ${p => p.theme.colors.textSecondaryDark};
  color: ${p => p.theme.colors.textPrimaryDark};

  ${mediaqueries.tablet`
    margin-bottom: 48px;
  `}
`;


const ArticlesListContainer = styled.div`
  transition: opacity 0.25s;
`;

const Item = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 80px;
  row-gap: 32px;

  ${mediaqueries.tablet`
    grid-template-columns: 1fr;
  `};

`;

const ImageContainer = styled.div`
  position: relative;
  height: 900px;
  border-radius: 400px 0 400px 400px;
  overflow: hidden;
  transition: transform 0.3s var(--ease-out-quad);
  background-color: ${p => p.theme.colors.grey};

  & > div {
    height: 100%;
  }

  ${mediaqueries.desktop`
    height: 520px;
  `}

  ${mediaqueries.tablet`
    height: 580px;
    border-radius: 400px;
  `}
`;

const TextContainer = styled.div<{
  dark: boolean;
  backgroundColor: string;
}>`
  max-width: 480px;
  color: ${p => p.theme.colors.textPrimaryDark};

  ${mediaqueries.desktop`
    max-width: 320px;
  `}

  ${mediaqueries.tablet`
    position: relative;
    max-width: 100%;
  `}
`;

const SeeMore = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: inherit;
  font-family: ${p => p.theme.fonts.title};
  margin-top: 8px;
  opacity: .8;

  ${mediaqueries.tablet`
    justify-content: center;
  `}
`;
  
const IconWrap = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.textSecondaryDark};
  margin-right: 12px;
  transition: transform 0.3s var(--ease-out-quad), background-color 0.3s var(--ease-out-quad);
  
  svg {
    fill: ${p => p.theme.colors.primary};
  }
`;

const ArticleLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
  margin-bottom: -64px;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  // &:hover ${ImageContainer} {
  //   transform: scale(1.03);
  // }

  &:hover ${IconWrap} {
    background-color: ${p => p.theme.colors.accent};
    transform: scale(1.4);

    & svg {
      fill: ${p => p.theme.colors.textPrimaryDark} !important;
    }
  }

  ${mediaqueries.tablet`
    margin-bottom: 80px;

    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`;


const List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;

  &:not(:last-child) {
    margin-bottom: 75px;
  }

  & ${ArticleLink}:nth-of-type(even) {

    & ${ImageContainer} {
      border-radius: 0 400px 400px 400px;
      grid-area: 1 / 2 / 2 / 3;
    }
    
    & ${TextContainer} {
      grid-area: 1 / 1 / 2 / 2;
    }

    ${mediaqueries.tablet`
      & ${ImageContainer} {
        grid-area: 1 / 1 / 2 / 2;
        border-radius: 400px;
      }
      
      & ${TextContainer} {
        grid-area: 2 / 1 / 3 / 2;
      }
    `}
  }
  & ${ArticleLink}:nth-last-of-type(1) {
    & ${ImageContainer} {
      border-radius: 400px;
    }
  }

`;

const Title = styled(Headings.h3)`
  font-family: ${p => p.theme.fonts.title};
  color: inherit;
  margin-bottom: 16px;
  transition: color 0.3s ease-in-out;
  ${limitToTwoLines};

  ${mediaqueries.desktop`
    margin-bottom: 15px;
  `}

  ${mediaqueries.tablet`
    margin-bottom: 10px;
    text-align: center;
    -webkit-line-clamp: 3;
  `}

`;

const EyebrowHeading = styled(Headings.h5)`
  font-family: ${p => p.theme.fonts.title};
  font-weight: ${p => p.theme.fontsWeight.medium};
  color: inherit;
  opacity: .7;
  margin-bottom: 8px;

  ${mediaqueries.tablet`
    margin-bottom: 0;
    text-align: center;
  `}
`;

const Excerpt = styled.p<{
  hasOverflow: boolean;
  narrow: boolean;
  gridLayout: string;
}>`
  ${limitToTwoLines};
  font-size: 18px;
  margin-bottom: 10px;
  color: inherit;
  opacity: .7;
  font-family: ${p => p.theme.fonts.body};
  display: ${p => (p.hasOverflow && p.gridLayout === 'tiles' ? 'none' : 'box')};
  line-height: 28px;

  ${mediaqueries.desktop`
    display: -webkit-box;
  `}

  ${mediaqueries.tablet`
    margin-bottom; 16px;
    text-align: center;
  `}

`;

