import React from 'react';
import styled from '@emotion/styled';
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import Section from "@components/Section";
import Icons from "@icons";
import Image, { ImagePlaceholder } from '@components/Image';

import Headings from '@components/Headings';

import mediaqueries from '@styles/media';

const siteQuery = graphql`
{
  allArticle(limit: 5, sort: {order: DESC, fields: date}) {
    edges {
      node {
        title
        date(formatString: "DD MMM")
        slug
        secret
        hero {
          childImageSharp {
            fluid(maxWidth: 457, quality: 95, base64Width: 1) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  file(relativePath: {eq: "default-image.png"}) {
    id
    childImageSharp {
      fluid(maxWidth: 457, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`;


const LatestArticles: React.FC<{}> = () => {

  const result = useStaticQuery(siteQuery);
  const articlesThatArentSecret = result.allArticle.edges.filter(edge => !edge.node.secret);
  const imageDefault = result.file.childImageSharp.fluid;

  return (
    <Section narrow>
      <WriteContainer>
        <SectionTitleWrap>
          <SectionTitle> <em>Latest stories</em></SectionTitle>
          <ViewMore to={`/writing`} data-a11y="false">
            <IconWrap>
              <Icons.ArrowForward /> 
            </IconWrap>
            <span>View all stories</span>
          </ViewMore>
        </SectionTitleWrap>
        {articlesThatArentSecret.map((item, index) => (
          <ArticlesWrapper key={index}>
            <LatestArticle to={item.node.slug} data-a11y="false">
                {item.node.title}
                <ArticleDate>{item.node.date}</ArticleDate>
                {/* <Separator>/</Separator> */}
            </LatestArticle>
            <ImageWrap>
              { item.node.hero ? <Image src={item.node.hero.childImageSharp.fluid} /> : <Image src={imageDefault} /> }
            </ImageWrap>
          </ArticlesWrapper>
        ))}
      </WriteContainer>
    </Section>
  );
};

export default LatestArticles;

const WriteContainer = styled.div`
  position: relative;
  margin-top: 160px;

  ${mediaqueries.tablet`
    margin-top: 96px;
  `}
`;

const ImageWrap = styled.div`
  position: absolute;
  top: -50%;
  left: 45%;
  width: 400px;
  height: 300px;
  z-index: 9;
  border-radius: 12px;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.85);
  transform: scale(0.8) rotate(-10deg);
  transition: 
    transform 0.6s cubic-bezier(0, 1.36, 0.8, 1), 
    opacity 0.6s cubic-bezier(0, 1.36, 0.8, 1);
  
  & > div {
    height: 100%;
  }

  ${mediaqueries.tablet`
    display: none;
  `};

`;

const LatestArticle = styled(Link)`
  margin-top: 0;
  display: block;
  position: relative;
  font-family: ${p => p.theme.fonts.title};
  font-weight: ${p => p.theme.fontsWeight.medium};
  font-size: 48px;
	color: ${p => p.theme.colors.textSecondaryDark};
  transition: color 0.33s var(--ease-out-quart), box-shadow 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 24px 0;
  box-shadow: inset 0px -1px 0px ${p => p.theme.colors.horizontalRule};

  ${mediaqueries.tablet`
    font-size: 40px;
  `};

  ${mediaqueries.phablet`
    font-size: 36px;
    line-height: 40px;
  `};

  &:hover,
  &:hover sup {
    color: ${p => p.theme.colors.accent};
  }
`;


const ArticlesWrapper = styled.div`
  position: relative;

  &:hover ${ImageWrap} {
    opacity: 1;
    transform: scale(1) rotate(15deg) translate(40px);


  }
  &:hover  ${LatestArticle} {
    box-shadow: inset 0px -1px 0px ${p => p.theme.colors.accent}, inset 0px 1px 0px ${p => p.theme.colors.accent};
  }
`;

const ArticleDate = styled.sup`
  vertical-align: super;
  font-family: ${p => p.theme.fonts.title};
  font-size: 12px;
  text-transform: uppercase;
  color: ${p => p.theme.colors.grey};
  margin-left: 8px;
  transition: color 0.33s var(--ease-out-quart);
`;

const SectionTitleWrap = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  padding: 16px 0;
  box-shadow: inset 0px -2px 0px ${p => p.theme.colors.textSecondaryDark};
`;

const SectionTitle = styled(Headings.h4)`
  position: relative;
  color: ${p => p.theme.colors.textPrimaryDark};
`;

const IconWrap = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.textSecondaryDark};
  margin-right: 8px;
  transition: transform 0.3s var(--ease-out-quad), background-color 0.3s var(--ease-out-quad);
  
  svg {
    fill: ${p => p.theme.colors.primary};
  }
`;

const ViewMore = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: inherit;
  font-family: ${p => p.theme.fonts.title};
  margin-top: 8px;
  opacity: .8;
  justify-self: end;
  color: ${p => p.theme.colors.textPrimaryDark};
  transition: color 0.3s var(--ease-out-quad);
  
  &:hover {
    color: ${p => p.theme.colors.accent};

    & ${IconWrap} {
      background-color: ${p => p.theme.colors.accent};
      transform: scale(1.3);
      
      & svg {
        fill: ${p => p.theme.colors.textPrimaryDark};
      }
    }
  }
`;
  
