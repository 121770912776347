import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from "gatsby";

import Image from '@components/Image';
import Headings from "@components/Headings";
import Section from "@components/Section";
import mediaqueries from '@styles/media';


const siteQuery = graphql`
{
  allProjectsYaml {
    edges {
      node {
        external
        title
        url
        image {
          childImageSharp {
            fluid(maxWidth: 360, quality: 95, base64Width: 1) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`;

const SideProjects: React.FC<{}> = () => {

  const result = useStaticQuery(siteQuery).allProjectsYaml.edges;

  return (
    <Section narrow>
      <Heading>
        <em>The maker of</em>
      </Heading>
      <GridWrapper>
        {result.map((project, index) =>(
          project.node.external ?
          (
            <ProjectLinkExternal href={project.node.url} data-a11y="false" key={index} >
                <ImageWrap>
                  <Image
                    src={project.node.image.childImageSharp.fluid}
                    atl={project.node.title}
                  />
                </ImageWrap>
            </ProjectLinkExternal>
          )
          :
          (
            <ProjectLink to={project.node.url} data-a11y="false" key={index} >
                <ImageWrap>
                  <Image
                    src={project.node.image.childImageSharp.fluid}
                    atl={project.node.title}
                  />
                </ImageWrap>
            </ProjectLink>
          )
        ))
        }
      </GridWrapper>
      </Section>
  );
};

export default SideProjects;

const GridWrapper = styled.div`
  position: relative;
  display: grid;
  z-index: 1;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 64px;
  row-gap: 16px;
  margin-bottom: 96px;
  margin-top: 40px;

  ${mediaqueries.desktop`
    column-gap: 16px;
  `}
  
  ${mediaqueries.tablet`
    column-gap: 8px;
    margin-top: 24px;
    margin-bottom: 40px;
  `}

  & a {
    border-radius: 200px;
    overflow: hidden;

    &:nth-of-type(1) {
      border-radius: 200px 200px 200px 0;
    }

    &:nth-of-type(3) {
      border-radius: 200px 200px 0 200px;
    }

`;

const Heading = styled(Headings.h2) `
  color: ${p => p.theme.colors.textPrimaryDark};
  text-align: center;
  margin-top: 160px;

  ${mediaqueries.tablet`
    margin-top: 96px;
  `}

`;

const cardLink = p => css`
  position: relative;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover {
    transform: scale(1.05);
  }

`;

const ProjectLink = styled(Link)`
  ${cardLink};
`;

const ProjectLinkExternal = styled.a`
  ${cardLink};
`;

const ImageWrap = styled.div`
  
`;
